import Container from "@material-ui/core/Container/Container";
import Typography from "@material-ui/core/Typography/Typography";
import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import {
  CAlert,
  CFormControl,
  CTextField,
  SubmitButton
} from "../../utils/globalStyles";
import Layout from "../../components/layout";
import { signUp, user } from "../../utils/auth";

type Props = {
  navigatedTo?: true;
  location?: { state: { autofocus: string; navigatedTo?: true } };
};
const EditProfile: React.FC<Props> = ({ navigatedTo, location }) => {
  const [error, setError] = useState("");
  const [email, setEmail] = useState(user.getEmail());
  const [password, setPassword] = useState("");
  const [displayName, setDisplayName] = useState(
    typeof window !== "undefined" ? user.getDisplayName() : ""
  );
  useEffect(() => {
    user.addTrigger(() => {
      setEmail(user.getEmail());
      setDisplayName(user.getDisplayName());
    });
  }, []);
  return (
    <Layout>
      <Container maxWidth="sm">
        <>
          <Typography variant="h4">Edit Profile</Typography>
          <form
            onSubmit={async e => {
              e.preventDefault();
              if (typeof window === "undefined") return;
              setError("");
              try {
                const promises = [];
                if (user.getEmail() !== email)
                  promises.push(user.setEmail(email));
                if (user.getDisplayName() !== displayName)
                  promises.push(user.setDisplayName(displayName));
                await Promise.all(promises);
                location?.state?.navigatedTo ? navigate(-1) : navigate("/");
              } catch (e) {
                setError(e.message);
                console.log(e);
              }
            }}
          >
            <CFormControl fullWidth>
              <CTextField
                label="Display Name"
                variant="filled"
                autoFocus={location?.state?.autofocus === "displayName"}
                value={displayName}
                required
                type="text"
                onChange={e => setDisplayName(e.target.value)}
              />
            </CFormControl>
            <CFormControl fullWidth>
              <CTextField
                label="Email"
                variant="filled"
                autoFocus={location?.state?.autofocus === "email"}
                value={email}
                required
                type="email"
                onChange={e => setEmail(e.target.value)}
              />
            </CFormControl>
            <SubmitButton color="primary" variant="contained" type="submit">
              Update
            </SubmitButton>
            {error ? (
              <CAlert severity="error" variant="outlined">
                {error}
              </CAlert>
            ) : null}
          </form>
        </>
      </Container>
    </Layout>
  );
};

export default EditProfile;
